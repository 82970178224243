import React from "react"
import UnderConstruction from "../components/UnderConstruction"

const Guaranties = () => {
    return (
        <>
            <UnderConstruction />
        </>
    )
}

export default Guaranties
